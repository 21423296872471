import React, { useContext, useEffect, useRef, useState } from 'react'
import './SupplierProfile.css'

import { ICity, IDropdownOption, IRegion, ISupplierEditProfile } from '../../../../models/models'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import { Context } from '../../../../index'
import CustomDropdown from '../../../customUI/CustomDropdown/CustomDropdown'
import CustomInput from '../../../customUI/CustomInput/CustomInput'
import { ModalContext } from '../../../../context/ModalContext'
import {
	Popup_SupplierChangeImage
} from '../../../Supplier_UI_kit/Popup_SupplierChangeImage/Popup_SupplierChangeImage'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CabinetSellerService from '../../../../services/CabinetSellerService'
import HomeService from '../../../../services/HomeService'
import { SpaRoutes } from '../../../../Routes/spaRoutes'
import AdminService from '../../../../services/AdminService'
import KladrInputRegion from '../../../customUI/CustomDropdown/KladrInputRegion'
import KladrInputCity from '../../../customUI/CustomDropdown/KladrInputCity'


function SupplierProfile() {
	const [isVisible, setIsVisible] = useState(true)
	let { supplierId } = useParams()
	
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const { store } = useContext(Context)
	const [loadedUserData, setLoadedUserData] = useState(false)
	const [loadedRegions, setLoadedRegions] = useState(false)
	const [regionName, setRegionName] = useState('')
	const [cityName, setCityName] = useState('')
	const [loadedAll, setLoadedAll] = useState(false)
	const [userData, setUserData] = useState<ISupplierEditProfile>({})
	const [regions, setRegions] = useState<IRegion[]>([])
	const [cities, setCities] = useState<ICity[]>([])
	const [typOrganisationName, setTypOrganisationName] = useState('Тип организации')
	
	const inputRef = useRef(null)
	const input2Ref = useRef(null)
	const [avatarFile, setAvatarFile] = useState<File>()
	const [previewAvatar, setPreviewAvatar] = useState<string>()
	const [oldPreviewAvatar, setOldPreviewAvatar] = useState<string>()
	const [logoFile, setLogoFile] = useState<File>()
	const [previewLogo, setPreviewLogo] = useState<string>()
	const [oldPreviewLogo, setOldPreviewLogo] = useState<string>()
	const [fullName, setFullName] = useState<string>()
	const [oldFullName, setOldFullName] = useState<string>()
	const [oldParol, setOldParol] = useState<string>()
	const [newParol, setNewParol] = useState<string>()
	const [parolAgain, setParolAgain] = useState<string>()
	
	const [fullNameError, setFullNameError] = useState(false)
	const [fullNameErrorMsg, setFullNameErrorMsg] = useState<any>([])
	const [phoneError, setPhoneError] = useState(false)
	const [phoneErrorMsg, setPhoneErrorMsg] = useState<any>([])
	const [emailError, setEmailError] = useState(false)
	const [emailErrorMsg, setEmailErrorMsg] = useState<any>([])
	const [birthdayError, setBirthdayError] = useState(false)
	const [birthdayErrorMsg, setBirthdayErrorMsg] = useState<any>([])
	const [statusChangesPersonalData, setStatusChangesPersonalData] = useState('')
	
	const [streetError, setStreetError] = useState(false)
	const [streetErrorMsg, setStreetErrorMsg] = useState<any>([])
	const [numberError, setNumberError] = useState(false)
	const [numberErrorMsg, setNumberErrorMsg] = useState<any>([])
	const [postIndexError, setPostIndexError] = useState(false)
	const [postIndexErrorMsg, setPostIndexErrorMsg] = useState<any>([])
	const [regionIdError, setRegionIdError] = useState(false)
	const [regionIdErrorMsg, setRegionIdErrorMsg] = useState<any>([])
	const [selectedRegionId, setSelectedRegionId] = useState<string>('')
	const [regionSuccess, setRegionSuccess] = useState<string>('false')
	const [citySuccess, setCitySuccess] = useState<string>('false')
	const [cityIdError, setCityIdError] = useState(false)
	const [cityIdErrorMsg, setCityIdErrorMsg] = useState<any>([])
	const [regionError, setRegionError] = useState<string>('')
	const [cityError, setCityError] = useState<string>('')
	const [typOrganisationError, setTypOrganisationError] = useState(false)
	const [typOrganisationErrorMsg, setTypOrganisationErrorMsg] = useState<any>([])
	const [innError, setInnError] = useState(false)
	const [innErrorMsg, setInnErrorMsg] = useState<any>([])
	const [officialNameError, setOfficialNameError] = useState(false)
	const [officialNameErrorMsg, setOfficialNameErrorMsg] = useState<any>([])
	const [displayedNameError, setDisplayedNameError] = useState(false)
	const [displayedNameErrorMsg, setDisplayedNameErrorMsg] = useState<any>([])
	const [name1SError, setName1SError] = useState(false)
	const [name1SErrorMsg, setName1SErrorMsg] = useState<any>([])
	const [parol1SError, setParol1SError] = useState(false)
	const [parol1SErrorMsg, setParol1SErrorMsg] = useState<any>([])
	const [statusChangesCompanyData, setStatusChangesCompanyData] = useState('')
	
	const [oldParolError, setOldParolError] = useState(false)
	const [oldParolErrorMsg, setOldParolErrorMsg] = useState<any>([])
	const [newParolError, setNewParolError] = useState(false)
	const [newParolErrorMsg, setNewParolErrorMsg] = useState<any>([])
	const [parolAgainError, setParolAgainError] = useState(false)
	const [ParolAgainErrorMsg, setParolAgainErrorMsg] = useState<any>([])
	const [statusChangesParol, setStatusChangesParol] = useState('')
	
	const [openAvatarPopup, setOpenAvatarPopup] = useState(false)
	const [openLogoPopup, setOpenLogoPopup] = useState(false)
	const { open, close } = useContext(ModalContext)
	const navigate = useNavigate()
	const OpenAvatarPopupHandler = () => {
		setOpenAvatarPopup(true)
		open()
	}
	const CloseAvatarPopupHandler = () => {
		setOpenAvatarPopup(false)
		close()
	}
	const OpenLogoPopupHandler = () => {
		setOpenLogoPopup(true)
		open()
	}
	const CloseLogoPopupHandler = () => {
		setOpenLogoPopup(false)
		close()
	}
	
	useEffect(() => {
		if (!loadedUserData) {
			store.DataLoadingON()
			if (store.roles.includes('Admin') && supplierId) {
				setIsVisible(false)
			}
			const GetSupplier = store.roles.includes('Admin') && supplierId ? AdminService.getSupplier(supplierId) : CabinetSellerService.getProfileData()
			GetSupplier.then(response => {
				let fixdata = response.data
				
				if (response.data['postCode'] == 0) fixdata['postCode'] = ''
				if (response.data['inn'] == 0) fixdata['inn'] = ''
				setUserData(fixdata)
				setFullName(`${fixdata['lastName']} ${fixdata['firstName']} ${fixdata['vatherName']}`)
				setOldFullName(`${fixdata['lastName']} ${fixdata['firstName']} ${fixdata['vatherName']}`)
				if (response.data['urlAvatar']) {
					setPreviewAvatar(store.imgServer + response.data['urlAvatar'])
					setOldPreviewAvatar(store.imgServer + response.data['urlAvatar'])
				} else {
					setPreviewAvatar('/Pictures/supplierNoImage.svg')
					setOldPreviewAvatar('/Pictures/supplierNoImage.svg')
				}
				if (response.data['urlLogo']) {
					setPreviewLogo(store.imgServer + response.data['urlLogo'])
					setOldPreviewLogo(store.imgServer + response.data['urlLogo'])
				} else {
					setPreviewLogo('/Pictures/supplierNoImage.svg')
					setOldPreviewLogo('/Pictures/supplierNoImage.svg')
				}
				
			}).catch(() => {
				store.ErrorON()
			}).finally(() => {
				store.DataLoadingOFF()
			}).then(() => setLoadedUserData(true))
		}
	}, [])
	
	useEffect(() => {
		//if (!loadedRegions) {
		//    HomeService.GetRegions().then(response => {
		//        setRegions(response.data.regions)
		//    })
		//        .then(() =>
		setLoadedRegions(true)
		//)
		//}
	}, [loadedUserData])
	
	useEffect(() => {
		if (loadedUserData && loadedRegions) {
			setRegionName(userData['regionId'] ? userData['regionId'] : '')
			if (userData['regionId']) {
				HomeService.getKladrRegion(userData['regionId'].split(' ')[0]).then(response => {
					if (response.data.output.result.result) {
						let kladrResult = response.data.output.result.result
						kladrResult.map((item: { id: string; name: string; type: string; guid: string; parentGuid: string; }) => {
							if (item.id !== 'Free' && item.name + ' ' + item.type.toLowerCase() === userData['regionId']) {
								setSelectedRegionId(item.id)
							}
						})
					}
				}).then(() => {
					setCityName(userData['cityId'] ? userData['cityId'] : '')
				})
			}
			
			setLoadedAll(true)
		}
	}, [loadedUserData])//, regions])
	
	useEffect(() => {
		if (!avatarFile) {
			setPreviewAvatar(undefined)
			return
		}
		
		const objectUrl = URL.createObjectURL(avatarFile)
		setPreviewAvatar(objectUrl)
		
		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl)
	}, [avatarFile])
	
	useEffect(() => {
		if (!logoFile) {
			setPreviewLogo(undefined)
			return
		}
		const objectUrl = URL.createObjectURL(logoFile)
		setPreviewLogo(objectUrl)
		
		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl)
	}, [logoFile])
	
	const checkError = (value: string | any, setError: any, setErrorMsg: any, message: string | any, type: number) => {
		if (type === 0 && value === undefined || value === '' || value === null) {
			setError(true)
			setErrorMsg(['Пустое поле'])
			return true
		} else if (type === 1 && /_/.test(value as string)) {
			setError(true)
			setErrorMsg([message])
			return true
		} else if (type === 2 && !/@/.test(value as string)) {
			setError(true)
			setErrorMsg([message])
			return true
		} else if (type === 3 && value?.trim().split('.').length !== 3) {
			setError(true)
			setErrorMsg([message])
			return true
		} else if (type === 4 && value.trim().split(' ').length < 3) {
			setError(true)
			setErrorMsg([message])
			return true
		} else {
			setError(false)
			return false
		}
	}
	
	
	const handleInputChange = (e: any) => {
		const { name, value } = e.target
		
		setUserData({
			...userData,
			[name]: value
		})
		
		if (name == 'fullName') {
			setFullName(e.target.value)
			if (e.target.value.split(' ').length === 3) {
				let ArrName = e.target.value.trim().split(' ')
				setUserData({
					...userData,
					['lastName']: ArrName[0],
					['firstName']: ArrName[1],
					['vatherName']: ArrName[2]
				})
			}
		}
	}
	
	const subYears = (num: number) => {
		let date = new Date(Date.now())
		let years = date.getFullYear() - num
		date.setFullYear(years)
		return date
	}
	
	const parseDateFromString = (input: any) => {
		let date = new Date
		date.setDate(parseInt(input.slice(0, 2)))
		date.setMonth(parseInt(input.slice(3, 5)) - 1)
		date.setFullYear(parseInt(input.slice(6, 10)))
		let test = Date.parse(date.toDateString())
		if (isNaN(test)) {
			return undefined
		} else {
			return date
		}
	}
	
	const handleDateChange = (date: Date | null) => {
		let value = ''
		if (date != null) {
			date.getDate() < 10 ?
				value += '0' + date.getDate().toString()
				: value += date.getDate().toString()
			value += '.'
			date.getMonth() + 1 < 10 ?
				value += '0' + (date.getMonth() + 1).toString()
				: value += (date.getMonth() + 1).toString()
			value += '.' + date.getFullYear()
		}
		setUserData({
			...userData,
			['birthday']: value
		})
	}
	
	const onRegionsSelectChange = (name: string, key: string) => {
		const RegionId = name
		setUserData({
			...userData,
			['regionId']: RegionId
		})
		setSelectedRegionId(key)
		setCityName('')
		setCitySuccess('false')
	}
	
	const onCitySelectChange = (val: string) => {
		let CityId = val
		setUserData({
			...userData,
			['cityId']: CityId
		})
	}
	useEffect(() => {
		if (regionSuccess != 'true')
			setRegionError('Регион не найден!')
		else
			setRegionError('')
	}, [regionSuccess])
	
	useEffect(() => {
		if (citySuccess != 'true')
			setCityError('Город не найден!')
		else
			setCityError('')
	}, [citySuccess])
	useEffect(() => {
		setUserData({
			...userData,
			['regionId']: regionName
		})
	}, [regionName])
	
	useEffect(() => {
		setUserData({
			...userData,
			['cityId']: cityName
		})
	}, [cityName])
	
	const onOrganisationSelectChange = (val: string) => {
		setUserData({
			...userData,
			['typOrganisation']: val
		})
	}
	
	// let ArrayRegions: IDropdownOption[] = []
	// regions.map((item) => {
	//     ArrayRegions.push({key: item.regionId, name: item.name})
	// })
	
	// let ArrayCitys: IDropdownOption[] = []
	// cities.map((item) => {
	//     ArrayCitys.push({key: item.cityId, name: item.name})
	// })
	
	let ArrayOrganisations: IDropdownOption[] = [{ key: 'ООО', name: 'ООО' }, { key: 'ИП', name: 'ИП' }, {
		key: 'Физ.Лицо',
		name: 'Физ.Лицо'
	}]
	
	
	const selectAvatarPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files.length === 0) {
			setAvatarFile(undefined)
			return
		}
		// I've kept this example simple by using the first image instead of multiple
		setAvatarFile(event.target.files[event.target.files.length - 1])
	}
	
	const selectLogoPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files.length === 0) {
			setLogoFile(undefined)
			return
		}
		// I've kept this example simple by using the first image instead of multiple
		setLogoFile(event.target.files[event.target.files.length - 1])
	}
	
	const onAvatarClick = () => {
		// @ts-ignore
		inputRef.current?.click()
	}
	
	const onLogoClick = () => {
		// @ts-ignore
		input2Ref.current?.click()
	}
	
	const uploadAvatar = () => {
		let data = {}
		if (avatarFile) {
			data = { 'avatarFile': avatarFile }
		}
		CabinetSellerService.uploadProfileAvatar(data).then(response => {
			//console.log(response.status)
		})
		if (avatarFile) {
			const objectUrl = URL.createObjectURL(avatarFile)
			setOldPreviewAvatar(objectUrl)
			return () => URL.revokeObjectURL(objectUrl)
		}
	}
	const uploadLogo = () => {
		let data = {}
		if (logoFile) {
			data = { 'logoFile': logoFile }
		}
		CabinetSellerService.uploadProfileLogo(data).then(response => {
			//console.log(response.status)
		})
		if (logoFile) {
			const objectUrl = URL.createObjectURL(logoFile)
			setOldPreviewLogo(objectUrl)
			return () => URL.revokeObjectURL(objectUrl)
		}
	}
	const createFormData = (data: any) => {
		return Object.keys(data).reduce((formData, key) => {
			formData.append(key, data[key])
			return formData
		}, new FormData())
	}
	const refactorUserData = (data: { [x: string]: any; }) => {
		for (const key in data) {
			if (data[key] === null || data[key] === undefined)
				delete data[key]
		}
		return data
	}
	
	useEffect(() => {
		setTimeout(function() {
			setStatusChangesPersonalData('')
		}, 5000)
	}, [statusChangesPersonalData])
	
	const uploadPersonalData = async () => {
		//email can't be changed for now. email: userData["email"],
		
		let mydata: any = {
			firstName: userData['firstName'],
			lastName: userData['lastName'],
			vatherName: userData['vatherName'],
			telefon: userData['telefon'],
			birthday: userData['birthday']
		}
		const data = createFormData(refactorUserData(mydata))
		CabinetSellerService.uploadProfilePersonalData(data).then(response => {
			
			if (response.status === 200)
				setStatusChangesPersonalData('Успешно')
		}).catch((error) => {
			if (error.response)
				setStatusChangesPersonalData('Что-то пошло не так, попробуйте позже еще раз')
		})
		
	}
	const checkErrorsPersonalData = (_callback: () => void) => {
		let errors = false
		if (checkError(fullName, setFullNameError, setFullNameErrorMsg, 'Неверно введено ФИО', 4)) errors = true
		if (checkError(userData['telefon'], setPhoneError, setPhoneErrorMsg, 'Неверно введен номер телефона', 1)) errors = true
		if (checkError(userData['email'], setEmailError, setEmailErrorMsg, 'Неверно введена почта', 2)) errors = true
		if (checkError(userData['birthday'], setBirthdayError, setBirthdayErrorMsg, 'Неверно введена дата рождения', 3)) errors = true
		if (!errors) {
			_callback()
		}
	}
	const BtnPersonalDataClick = () => {
		checkErrorsPersonalData(uploadPersonalData)
	}
	
	useEffect(() => {
		setTimeout(function() {
			setStatusChangesCompanyData('')
		}, 5000)
	}, [statusChangesCompanyData])
	
	const uploadCompanyData = () => {
		let mydata: any = {
			street: userData['street'],
			number: userData['number'],
			postIndex: userData['postIndex'],
			cityId: userData['cityId'],
			regionId: userData['regionId'],
			typOrganisation: userData['typOrganisation'],
			inn: userData['inn'],
			officialName: userData['officialName'],
			displayedName: userData['displayedName'],
			name1S: userData['name1S'],
			parol1S: userData['parol1S']
		}
		const data = createFormData(refactorUserData(mydata))
		CabinetSellerService.uploadProfileCompanyData(data).then(response => {
			
			if (response.status === 200)
				setStatusChangesCompanyData('Успешно')
		}).catch((error) => {
			if (error.response)
				setStatusChangesCompanyData('Что-то пошло не так, попробуйте позже еще раз ')
		})
	}
	const checkErrorsCompanyData = (_callback: () => void) => {
		let errors = false
		if (checkError(userData['street'], setStreetError, setStreetErrorMsg, '', 0)) errors = true
		if (checkError(userData['number'], setNumberError, setNumberErrorMsg, '', 0)) errors = true
		if (checkError(userData['postIndex'], setPostIndexError, setPostIndexErrorMsg, 'Неверно введен почтовый индекс', 1)) errors = true
		if (checkError(userData['regionId'], setRegionIdError, setRegionIdErrorMsg, 'Не выбран регион', 0)) errors = true
		if (checkError(userData['cityId'], setCityIdError, setCityIdErrorMsg, 'Не выбран город', 0)) errors = true
		if (checkError(userData['typOrganisation'], setTypOrganisationError, setTypOrganisationErrorMsg, '', 0)) errors = true
		if (checkError(userData['inn'], setInnError, setInnErrorMsg, 'Неверно введен инн', 1)) errors = true
		if (checkError(userData['officialName'], setOfficialNameError, setOfficialNameErrorMsg, '', 0)) errors = true
		if (checkError(userData['displayedName'], setDisplayedNameError, setDisplayedNameErrorMsg, '', 0)) errors = true
		//if (checkError(userData["name1S"], setName1SError, setName1SErrorMsg, "", 0)) errors = true
		//if (checkError(userData["parol1S"], setParol1SError, setParol1SErrorMsg, "", 0)) errors = true
		if (cityError !== '') errors = true
		if (regionError !== '') errors = true
		if (citySuccess === 'false') errors = true
		if (regionSuccess === 'false') errors = true
		if (!errors) {
			_callback()
		}
	}
	const BtnCompanyDataClick = () => {
		checkErrorsCompanyData(uploadCompanyData)
	}
	
	
	useEffect(() => {
		setTimeout(function() {
			setStatusChangesParol('')
		}, 5000)
	}, [statusChangesParol])
	const uploadNewPassword = () => {
		
		if (!oldParolError && !newParolError && !parolAgainError) {
			if (newParol && oldParol) {
				let data = {
					'oldParol': oldParol.toString(),
					'newParol': newParol.toString()
				}
				CabinetSellerService.uploadProfileNewParol(data).then(response => {
					if (response.status === 200)
						setStatusChangesParol('Успешно')
				}).catch((error) => {
					if (error.response) {
						if (error.response.status === 500) {
							setOldParolErrorMsg(['Неверный пароль'])
							setOldParolError(true)
						} else
							setStatusChangesParol('Что-то пошло не так, попробуйте позже еще раз')
					}
				})
			}
		}
	}
	
	const checkErrorsNewPassword = (_callback: () => void) => {
		let errors = false
		
		if (!oldParol) {
			setOldParolError(true)
			setOldParolErrorMsg(['Пустое поле'])
			errors = true
		}
		
		if (newParol) {
			const mass = [
				['Длина пароля минимум 6 символов.', /[0-9a-zA-Z!@#$%^&*.]{6,}/],
				['Не хватает цифр.', /(?=.*[0-9])/],
				['Не хватает маленькой буквы.', /(?=.*[a-z])/]
			]
			
			let ermass: any = []
			
			for (let i = 0; i < mass.length; i++) {
				const reg = new RegExp(mass[i][1])
				if (!reg.test(String(newParol))) {
					ermass[i] = `${mass[i][0]}`
				}
			}
			if (ermass.length != 0) {
				setNewParolError(true)
				setNewParolErrorMsg(ermass)
				errors = true
			}
		} else {
			setNewParolError(true)
			setNewParolErrorMsg(['Пустое поле'])
			errors = true
		}
		if (!parolAgain) {
			setParolAgainError(true)
			setParolAgainErrorMsg(['Пустое поле'])
		} else {
			if (newParol != parolAgain) {
				setParolAgainError(true)
				setParolAgainErrorMsg(['Пароли не совпадают'])
				errors = true
			}
		}
		
		if (!errors) {
			_callback()
		}
	}
	const BtnNewPasswordClick = () => {
		checkErrorsNewPassword(uploadNewPassword)
	}
	return (
		<>{(!store.isError && !store.isDataLoading) &&
			<>
				<div className={'supplier-profile-container'}>
					<div className={'supplier-profile-Info'}>
						
						<div className={'supplier-profile-names'}>
							<div className={'supplier-navigate'}>
								<img className={'supplier-navigate-image'} onClick={() => navigate(-1)}
								     src={'/Pictures/strelochka.svg'} />
							</div>
							<div
								className={'supplier-profile-name'}>{oldFullName}</div>
							<div className={'supplier-profile-id'}>ID: {userData['id']}</div>
							<div className={'supplier-profile-role'}>Поставщик</div>
							
							<div className={'supplier-select-component-storages'}>
								<Link
									to={store.roles.includes('Admin') && supplierId ? SpaRoutes.SUP_MAGAZINE + `/${supplierId}` : SpaRoutes.SUP_MAGAZINE}>
									<div className={'supplier-dropDown-storage'}></div>
								</Link>
								<Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${-1}` + `/${-1}`}
								      style={isVisible ? {} : { visibility: 'hidden' }}>
									<div className={'supplier-select-component-storages-add-store'}>Добавить склад</div>
								</Link>
							</div>
						</div>
						<div className={'supplier-profile-images'}>
							<div className={'supplier-profile-image-container'}>
								<div className={'supplier-images-text'}>Аватар</div>
								<img
									className={'supplier-image'}
									src={oldPreviewAvatar}
									alt={'avatar picture'}
									onClick={onAvatarClick}
								/>
								
								<button className={'supplier-image-button'} onClick={OpenAvatarPopupHandler}
								        style={isVisible ? {} : { visibility: 'hidden' }}>
									Редактировать
								</button>
								{openAvatarPopup && <Popup_SupplierChangeImage
									title={'Изменить аватар'}
									textOnButton={'Подтвердить'}
									onChangeImage={uploadAvatar}
									openDirectory={onAvatarClick}
									image={previewAvatar}
									onClose={CloseAvatarPopupHandler}>
									<input
										ref={inputRef}
										style={{ display: 'none' }}
										type='file'
										
										name={'profilePicture'}
										onChange={selectAvatarPicture}
										accept='image/*'
									/>
								</Popup_SupplierChangeImage>
								}
							</div>
							<div className={'supplier-profile-image-container'}>
								<div className={'supplier-images-text'}>Логотип</div>
								
								<img
									className={'supplier-image'}
									src={oldPreviewLogo}
									alt={'logo picture'}
								/>
								<button className={'supplier-image-button'} onClick={OpenLogoPopupHandler}
								        style={isVisible ? {} : { visibility: 'hidden' }}>
									Редактировать
								</button>
								{openLogoPopup && <Popup_SupplierChangeImage
									title={'Изменить логотип'}
									textOnButton={'Подтвердить'}
									onChangeImage={uploadLogo}
									openDirectory={onLogoClick}
									image={previewLogo}
									onClose={CloseLogoPopupHandler}>
									<input
										ref={input2Ref}
										style={{ display: 'none' }}
										type='file'
										
										name={'profilePicture'}
										onChange={selectLogoPicture}
										accept='image/*'
									/>
								</Popup_SupplierChangeImage>
								}
							</div>
						</div>
					
					</div>
					
					<hr className={'supplier-hr'} />
					
					<div className={'supplier-personal-data'}>
						<div className={'supplier-header'}>Личные данные</div>
						<div className={'supplier-inputs'}>
							<div className={'supplier-full-input'}>
								
								<div className={'supplier-input-text'}>ФИО</div>
								<CustomInput
									name={'fullName'}
									icon={isVisible}
									disabled={!isVisible}
									value={fullName}
									error={fullNameError}
									errorMsg={fullNameErrorMsg}
									placeHolder={'ФИО'}
									onChange={(event) => {
										handleInputChange(event)
										setFullNameError(false)
									}}
									inputSize={'large'}
								/>
							</div>
							
							<div className={'supplier-half-inputs'}>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Номер телефона</div>
									<CustomInput
										name={'telefon'}
										type={'telephone'}
										icon={isVisible}
										disabled={!isVisible}
										error={phoneError}
										errorMsg={phoneErrorMsg}
										value={userData['telefon']}
										placeHolder={'Номер телефона'}
										onChange={(event) => {
											handleInputChange(event)
											setPhoneError(false)
										}}
										inputSize={'large'}
									/>
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Электронная почта</div>
									
									<CustomInput
										name={'email'}
										icon={isVisible}
										disabled={!isVisible}
										error={emailError}
										errorMsg={emailErrorMsg}
										value={userData['email']}
										placeHolder={'Электронная почта'}
										onChange={(event) => {
											handleInputChange(event)
											setEmailError(false)
										}}
										inputSize={'large'}
									/>
								</div>
							</div>
							
							<div className={'supplier-one-half-input-left'}>
								<div className={'supplier-half-input'}>
									<div className={'supplier-input-text'}>Дата рождения</div>
									<div className={'supplier-datePicker'}>
										<input type={'date'}
										       name={'birthday'}
										       
										       disabled={!isVisible}
										       placeholder={'Дата рождения'}
										       onChange={(e) => {
											       handleDateChange(new Date(e.target.value))
											       setBirthdayError(false)
										       }}
										       value={userData['birthday'] ? parseDateFromString(userData['birthday'])?.toLocaleDateString('fr-ca') : ''}
										/>
										{birthdayError &&
											<div className={'сustom-input-error'}>
												<img
													className={'сustom-input-error-image'}
													src={'/Pictures/errorIcon.svg'}
												/>
												<div className={'сustom-input-error-text'}>
													{birthdayErrorMsg.map((e: any) => <div>{e}</div>)}
												</div>
											</div>}
										<img className={'supplier-datePicker-image'}
										     src={'/Pictures/datePickerIcon.svg'} />
									</div>
								</div>
							</div>
						</div>
						<div className={'supplier-saveChanges-container'}>
							<div className={'supplier-saveChanges-status'}
							     style={{ color: statusChangesPersonalData === 'Успешно' ? 'green' : '#db2020' }}>{statusChangesPersonalData}</div>
							<button className={'supplier-saveChanges-button'} onClick={BtnPersonalDataClick}
							        style={isVisible ? {} : { display: 'none' }}>
								Сохранить изменения
							</button>
						</div>
					</div>
					
					
					<hr className={'supplier-hr'} />
					
					<div className={'supplier-organization-data'}>
						<div className={'supplier-header'}>Данные об организации</div>
						<div className={'supplier-inputs'}>
							<div className={'supplier-half-inputs'}>
								<div className={'supplier-address-inputs'}>
									
									<div className={'supplier-address-input-street'}>
										<div className={'supplier-input-text'}>Адрес регистрации</div>
										
										<CustomInput
											name={'street'}
											value={userData['street']}
											error={streetError}
											disabled={!isVisible}
											errorMsg={streetErrorMsg}
											placeHolder={'Улица'}
											onChange={(event) => {
												handleInputChange(event)
												setStreetError(false)
											}}
											inputSize={'large'}
										/>
									</div>
									<div className={'supplier-address-input-numHome'}>
										<CustomInput
											name={'number'}
											value={userData['number']}
											error={numberError}
											disabled={!isVisible}
											errorMsg={numberErrorMsg}
											placeHolder={'Дом'}
											onChange={(event) => {
												handleInputChange(event)
												setNumberError(false)
											}}
											inputSize={'large'}
										/>
									</div>
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Почтовый индекс</div>
									
									<CustomInput
										name={'postIndex'}
										type={'inputMask'}
										mask={'999999'}
										value={userData['postIndex']}
										icon={isVisible}
										disabled={!isVisible}
										error={postIndexError}
										errorMsg={postIndexErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setPostIndexError(false)
										}}
										placeHolder={'Почтовый индекс'}
										inputSize={'large'}
									/>
								</div>
							</div>
							
							{<div className={'supplier-half-inputs'}>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Регион</div>
									
									<div className={'supplier-select-component-input'}>
										{/* <CustomInput
                                            name={"region"}
                                            value={userData["regionId"]}
                                            error={regionIdError}
                                            disabled={!isVisible}
                                            errorMsg={regionIdErrorMsg}
                                            placeHolder={"Регион"}
                                            onChange={(event) => {
                                               onRegionsSelectChange(event.target.value,"")
                                               setRegionIdError(false)
                                            }}
                                            inputSize={"large"}
                                        /> */}
										{<KladrInputRegion
											styleDropdownDisplay={'supplier-dropDown-input'}
											icon={isVisible}
											disabled={!isVisible}
											error={regionIdError}
											errorMsg={regionIdErrorMsg}
											dropdownSize={'large'}
											value={regionName}
											valueSetter={setRegionName}
											successSetter={setRegionSuccess}
											onChangeHandler={onRegionsSelectChange}
										/>}
									</div>
									{isVisible && <div className={'invalid-feedback'} style={{ color: 'red' }}>{regionError}</div>}
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Город</div>
									
									<div className={'supplier-select-component-input'}>
										{/* <CustomInput
                                            name={"city"}
                                            value={userData["cityId"]}
                                            error={cityIdError}
                                            disabled={!isVisible}
                                            errorMsg={cityIdErrorMsg}
                                            placeHolder={"Город"}
                                            onChange={(event) => {
                                               onCitySelectChange(event.target.value)
                                               setCityIdError(false)
                                            }}
                                            inputSize={"large"}
                                        /> */}
										{<KladrInputCity
											styleDropdownDisplay={'supplier-dropDown-input'}
											icon={isVisible}
											regionId={selectedRegionId}
											disabled={!isVisible}
											error={cityIdError}
											value={cityName}
											dropdownSize={'large'}
											errorMsg={cityIdErrorMsg}
											successSetter={setCitySuccess}
											valueSetter={setCityName}
											onChangeHandler={onCitySelectChange}
										/>}
									</div>
									{isVisible && <div className={'invalid-feedback'} style={{ color: 'red' }}>{cityError}</div>}
								</div>
							</div>}
							
							<div className={'supplier-half-inputs'}>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Тип организации</div>
									
									<div className={'supplier-select-component-input'}>
										<CustomDropdown
											styleDropdownDisplay={'supplier-dropDown-input'}
											icon={isVisible}
											disabled={!isVisible}
											error={typOrganisationError}
											dropdownSize={'large'}
											errorMsg={typOrganisationErrorMsg}
											options={ArrayOrganisations} value={typOrganisationName}
											valueSetter={setTypOrganisationName}
											onChangeHandler={onOrganisationSelectChange}
										/>
									</div>
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>ИНН</div>
									
									<CustomInput
										disabled={isVisible ? !userData['typOrganisation'] : true}
										name={'inn'}
										type={'inputMask'}
										mask={userData['typOrganisation'] === 'ООО' ? '9999999999' : '999999999999'}
										value={userData['inn']}
										icon={isVisible}
										error={innError}
										errorMsg={innErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setInnError(false)
										}}
										inputSize={'large'}
									/>
								</div>
							</div>
							
							<div className={'supplier-half-inputs'}>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Юридическое название</div>
									
									<CustomInput
										name={'officialName'}
										value={userData['officialName']}
										icon={isVisible}
										disabled={!isVisible}
										error={officialNameError}
										errorMsg={officialNameErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setOfficialNameError(false)
										}}
										inputSize={'large'}
									/>
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>Название на нашем портале</div>
									
									<CustomInput
										name={'displayedName'}
										value={userData['displayedName']}
										icon={isVisible}
										disabled={!isVisible}
										error={displayedNameError}
										errorMsg={displayedNameErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setDisplayedNameError(false)
										}}
										inputSize={'large'}
									/>
								</div>
							</div>
							
							<div className={'supplier-half-inputs'}>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>“Name” для 1С</div>
									
									<CustomInput
										name={'name1S'}
										value={userData['name1S']}
										icon={isVisible}
										disabled={!isVisible}
										error={name1SError}
										errorMsg={name1SErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setName1SError(false)
										}}
										inputSize={'large'}
									/>
								</div>
								<div className={'supplier-half-input'}>
									
									<div className={'supplier-input-text'}>“Parol” для 1С</div>
									
									<CustomInput
										name={'parol1S'}
										value={userData['parol1S']}
										icon={isVisible}
										disabled={!isVisible}
										error={parol1SError}
										errorMsg={parol1SErrorMsg}
										onChange={(event) => {
											handleInputChange(event)
											setParol1SError(false)
										}}
										inputSize={'large'}
									/>
								</div>
							</div>
						
						</div>
						<div className={'supplier-saveChanges-container'}>
							<div className={'supplier-saveChanges-status'}
							     style={{ color: statusChangesCompanyData === 'Успешно' ? 'green' : '#db2020' }}>{statusChangesCompanyData}</div>
							<button className={'supplier-saveChanges-button'} onClick={BtnCompanyDataClick}
							        style={isVisible ? {} : { display: 'none' }}>
								Сохранить изменения
							</button>
						</div>
					</div>
					{isVisible && <>
						<hr className={'supplier-hr'} />
						
						<div className={'supplier-password'}>
							<div className={'supplier-header'}>Изменить пароль</div>
							<div className={'supplier-inputs'}>
								<div className={'supplier-half-inputs'}>
									<div className={'supplier-half-input'}>
										<div className={'supplier-input-text'}>Пароль</div>
										
										<CustomInput
											type={'password'}
											name={'password'}
											icon={isVisible}
											disabled={!isVisible}
											error={oldParolError}
											errorMsg={oldParolErrorMsg}
											placeHolder={'Пароль'}
											onChange={(e) => {
												setOldParol(e.target.value)
												setOldParolError(false)
											}}
											inputSize={'large'}
										/>
									</div>
									<div className={'supplier-half-input'}>
										
										<div className={'supplier-input-text'}>Новый пароль</div>
										<CustomInput
											type={'password'}
											name={'newParol'}
											icon={isVisible}
											disabled={!isVisible}
											error={newParolError}
											errorMsg={newParolErrorMsg}
											placeHolder={'Новый пароль'}
											onChange={(e) => {
												setNewParol(e.target.value)
												setNewParolError(false)
											}}
											inputSize={'large'}
										/>
									</div>
								</div>
								
								<div className={'supplier-one-half-input-right'}>
									<div className={'supplier-half-input'}>
										<div className={'supplier-input-text'}>Подтвердить пароль</div>
										
										<CustomInput
											type={'password'}
											name={'passwordAgain'}
											icon={isVisible}
											disabled={!isVisible}
											error={parolAgainError}
											errorMsg={ParolAgainErrorMsg}
											placeHolder={'Подтвердить пароль'}
											onChange={(e) => {
												setParolAgain(e.target.value)
												setParolAgainError(false)
											}}
											inputSize={'large'}
										/>
									</div>
								</div>
							
							</div>
							<div className={'supplier-saveChanges-container'}>
								<div className={'supplier-saveChanges-status'}
								     style={{ color: statusChangesParol === 'Успешно' ? 'green' : '#db2020' }}>{statusChangesParol}</div>
								<button className={'supplier-saveChanges-button'} onClick={BtnNewPasswordClick}
								        style={isVisible ? {} : { display: 'none' }}>
									Сохранить изменения
								</button>
							</div>
						
						</div>
					</>}
				
				</div>
			</>
		}
		</>
	)
}

export default SupplierProfile