import React, {useEffect, useRef, useState} from "react";
import {ISupplierAdress} from "../../../models/supplier/supplier";
import {ICity, IDropdownOption, IRegion} from "../../../models/models";
import "./CustomDropdown.css";
import {event} from "jquery";
import {ITimeDropdown} from "../../../models/OrderFormation/IOrderFormation";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import HomeService from "../../../services/HomeService";

interface Interface {
    importantId?:boolean;
    value?:string ;
    valueSetter:(val:any)=>any;
    successSetter?:(val:any)=>any;
    onChangeHandler:(val:any,key:any)=>any;
    dropdownSize?: "small" | "medium" | "large";
    styleDropdownDisplay?:string;
    styleDropdownList1?:string;
    styleDropdownList2?:string;
    icon?: boolean;
    error?: boolean;
    errorMsg?: [string] | any;
    disabled?: boolean;
    isRemoved?: boolean;
    placeholder?:string;
}

const KladrInputRegion = ({placeholder, importantId, value, valueSetter, successSetter = () => undefined, onChangeHandler, styleDropdownDisplay = "", icon, dropdownSize='small', error, errorMsg,styleDropdownList1='',styleDropdownList2='', disabled=false, isRemoved}:Interface) => {
    const [hideMe, setHideMe] = useState("true");
    const listRef = useRef(null);
    const [options,setOptions] = useState<any[]>([]);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        value && value.length > 0 &&
        //fetch("https://kladr-api.ru/api.php?query="+value+"&contentType=region&limit=5", {
        //    headers: {"Access-Control-Allow-Origin": "*"}
        //})//, mode:'no-cors'})//,{mode:'no-cors'})
        HomeService.getKladrRegion(value.split(" ")[0])
            .then(response => {
                let list:any[] = [];
                if (response.data.output.result.result){
                    let kladrResult = response.data.output.result.result

                    kladrResult.map((item: { id: string; name: string; type: string; guid: string; parentGuid:string; }) => {
                        if (item.id !== "Free"){
                            let name = item.name + " " + item.type.toLowerCase()
                            list.push( { name:name, key:item.id } )
                        }
                    })
                }
                if (list.length > 0){
                    setHideMe("false")
                }
                if (list.length === 1 && value === list[0].name){
                    setHideMe("true")
                    successSetter("true")
                }
                else {
                    successSetter("false")
                }
                if (list.length > 1 ){
                    successSetter("false")
                }
                if (list.length < 1 ){
                    successSetter("false")
                }
                setOptions(list)
            })
    }, [value])

    useOnClickOutside(ref, () => setHideMe("true"));

    const dropdownHandler = (val:any)=> {
        setHideMe("true");
        if (val.key)
            if (val.key != "no") {
                if (!importantId && typeof value !== "string") {
                    valueSetter({name: val.name, key: val.key})
                    onChangeHandler(val.name,val.key);
                } else {
                    valueSetter(val.name)
                    onChangeHandler(val.name,val.key);
                }
            }
        setHideMe("true");
    }
    const clickHandler = (event: any) => {
        if (!disabled) {
            let dropdowns = document.getElementsByClassName("DropdownDisplay")
            Array.prototype.forEach.call(dropdowns, function (item) {
                if (event?.currentTarget.identifier != item && item.dataset["hideme"] === "false") {
                    item.click();
                }
            })

            //if (options.length > 0) {
            //   hideMe == "true" ? setHideMe("false") : setHideMe("true");
            //} else {
            //    setHideMe("true");
            //}
            if (options.length >= 1 && options.some((op)=>{return op.name==value})){
                setHideMe("true")
                successSetter("true")
            }
            else{
                setHideMe("false")
            }
            if (options.length === 0){
                setHideMe("true")
            }
        }
    }

    const ListDropdown = options.map((item) => {
        return (
            <div
                className={"dropdown-item-name" + styleDropdownList2}
                onClick={() => dropdownHandler(item)}
                key={item.name}
            >
                {item.name}
            </div>
        )
    })

    return (
        <div className={"dropdown-internal-container"} ref={ref}>
            <div className={"DropdownDisplay " + styleDropdownDisplay} onClick={clickHandler} data-hideMe={hideMe}
                 data-size={dropdownSize}
                 style={{
                     borderColor: error ? "#db2020" : "",
                     paddingRight: icon ? error ? "75px" : "44px" : "21px"
                 }}>
                <input autoComplete={"off"} placeholder={placeholder?placeholder:''} className={"DropdownDisplayInput"} value={value} onChange={(event:any) => {
                    valueSetter(event.currentTarget.value)}}/>


            </div>
            <div ref={listRef} className={"dropdown-list-div"} data-hideMe={hideMe}
                 data-size={dropdownSize}>
                <div className={"dropdown-list-div-content"}>
                    {ListDropdown}
                </div>
            </div>
        </div>
    )
}
export default KladrInputRegion;