import "./TrainingCart.css";
import React, {useContext, useState} from "react";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {getParsedDate, removeGorod} from "../../../../../functions/functions";
import {Context} from "../../../../../index";
import {Link, useNavigate} from "react-router-dom";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import AdminService from "../../../../../services/AdminService";
import {PopUpTrainingCart} from "./PopUpTrainingCart";
import {IAttenders} from "../../../../../models/models";

interface ITrainingCart {
    id: string,
    title: string,
    blocked: boolean,
    cityName: string,
    organisator: string,
    organisatorId?: string,
    startDate: string,
    endDate: string,
    description: string,
    imageUrl: string,
    editable?: boolean,
    attending?: boolean,
    setAttending?: (id: string) => void
    onUpdateData?: () => void,
    setBlocked: (arg0: boolean, id: string) => void,
    openPopUp: () => void
    openPopUpAttenders?: () => void,
}


export const TrainingCart = ({
                                 id,
                                 title,
                                 blocked,
                                 setBlocked,
                                 cityName,
                                 openPopUpAttenders,
                                 attending,
                                 setAttending,
                                 organisator,
                                 organisatorId,
                                 startDate,
                                 endDate,
                                 description,
                                 imageUrl,
                                 editable,
                                 onUpdateData,
                                 openPopUp
                             }: ITrainingCart) => {
    const {store} = useContext(Context)
    const navigate = useNavigate();

    const handleTrainingCartClick = async () => {
        if (store.roles.includes('Dealer')) {
            CabinetSellerService.postSignUpForEvent({"Id": id}).then((response) => {
                    if (setAttending)
                        setAttending(id);
                }
            )
        } else if (store.roles.includes('Customer')) {
            CabinetBuyerService.postSignUpForEvent({"Id": id}).then(() => {
                if (setAttending)
                    setAttending(id);
            })
        }
    }


    const EventDelete = () => {
        if (window.confirm("Вы точно хотите удалить событие?")) {
            store.DataLoadingON()
            let mydata = {"Info1": id}
            CabinetSellerService.postDeleteCalendarEvent(mydata)
                .then((response) => {
                    if (response.status == 200) {
                        window.location.reload();
                        alert("Событие удалено")
                    }
                })
                .catch((error) => {
                    if (error.response.status === 500) {
                        alert("Такого события нет")
                    } else {
                        alert("Что-то пошло не так")
                    }
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }
    }

    const EventBlock = () => {
        if (window.confirm("Вы точно хотите заблокировать событие?")) {
            let mydata = {
                "id": id,
                "blocked": true
            }
            setBlocked(true, id);
            AdminService.postUpdateNewCalendarEvent(mydata)
        }
    }

    const EventUnBlock = () => {
        if (window.confirm("Вы точно хотите возобновить событие?")) {
            let mydata = {
                "id": id,
                "blocked": false
            }
            setBlocked(false, id);
            AdminService.postUpdateNewCalendarEvent(mydata)
        }
    }

    return (
        <div className="trainingCart-wrapper">
            <div className="trainingCart-container">
                <div className="trainingCart-top">
                    <div className="trainingCart-top-block">
                        <label>
                            <img src="/Pictures/Loc.svg" alt="..."/>
                            {removeGorod(cityName)}
                            <b>
                                {startDate &&
                                endDate ? (getParsedDate(startDate, true, false) === getParsedDate(endDate, true, false) ?
                                            `${getParsedDate(startDate, true, false)}` :
                                            `${getParsedDate(startDate, true, false)}-${getParsedDate(endDate, true, false)}`
                                    )
                                    : getParsedDate(startDate, true, false)
                                }
                            </b>
                        </label>
                    </div>
                    {editable &&
                        <div className="trainingCart-top-block-buttons">
                            <button onClick={openPopUpAttenders} className={"trainingCart-buttonAttenders"}/>
                            <button onClick={() => navigate(SpaRoutes.ADDTRAINING + `/${id}`)}
                                    className={"trainingCart-buttonEdit"}/>
                            <button onClick={EventDelete} className={"trainingCart-buttonDelete"}/>
                        </div>
                    }
                    {store.roles.includes('Admin') && (!blocked ?
                        <div className="trainingCart-top-block-buttons">
                            <button onClick={EventBlock} className={"trainingCart-buttonBlock"}/>
                        </div> :
                        <div className="trainingCart-top-block-buttons">
                            <button onClick={EventUnBlock} className={"trainingCart-buttonUnBlock"}/>
                        </div>)
                    }
                </div>
                <div className="trainingCart-bottom">
                    <div className="trainingCart-image-container">
                        {blocked && <div style={{
                            position: "relative",
                            top: "40px",
                            padding: "10px",
                            backgroundColor: "black",
                            color: "red",
                            fontSize: "20px",
                            maxHeight: "16px",
                            minHeight: "16px",
                            marginBottom: "-36px"
                        }}>
                            ЗАБЛОКИРОВАНО
                        </div>}
                        <img src={imageUrl ? store.imgServer + imageUrl : "/Pictures/Обучение.svg"}
                             style={imageUrl ? {} : {objectFit: "fill"}} alt="..." onClick={openPopUp}/>
                    </div>
                    <div className="trainingCart-bottom-block">
                        <p>
                            {title}
                        </p>
                        <label style={{display: window.innerWidth < 450? "none" : "block"}}>
                            {description}
                        </label>

                        {/*{editable || organisatorId === store.user.id ?

                            <Link to={store.roles[0] == "Dealer" ?
                                SpaRoutes.SUPPLIER_CHAT + "/:/:5/:" + id :
                                SpaRoutes.CHAT_DIALOG_PAGE + "/:/:5/:" + id}>Чат</Link>
                            :

                            attending ?
                                <Link to={store.roles[0] == "Dealer" ?
                                    SpaRoutes.SUPPLIER_CHAT + "/:/:5/:" + id :
                                    SpaRoutes.CHAT_DIALOG_PAGE + "/:/:5/:" + id}>Чат</Link>
                                :
                                <div onClick={handleTrainingCartClick}>Пойти</div>
                        }*/}
                        <div onClick={openPopUp}>Подробнее</div>
                    </div>
                </div>

            </div>

        </div>
    );
};
