import {observer} from 'mobx-react-lite'
import './ProductPage.css'
import {Link, useNavigate, useParams} from 'react-router-dom'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {Context} from '../../../index'
import Favorite from '../../customUI/Favorite/Favorite'
import Counter from '../../customUI/Counter/Counter'
import {GalleryThumbnails} from '../../customUI/GalleryThumbnails/GalleryThumbnails'
import {ImageWithSlides} from '../../customUI/ImageWithSlides/ImageWithSlides'
import ProductCard from '../../customUI/ProductCard/ProductCard'
import SwiperCore, {Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import CustomNavigateHeader from '../../customUI/CustomNavigateHeader/CustomNavigateHeader'
import {CustomIMG} from '../../customUI/CustomIMG/CustomIMG'
import HomeService from '../../../services/HomeService'
import {
    GetProductInfoResponse,
    OtherSupplier
} from '../../../models/response/getProductInfoResponse'
import CabinetBuyerService from '../../../services/CabinetBuyerService'
import {SpaRoutes} from '../../../Routes/spaRoutes'
import FunctionButton from '../../customUI/FunctionButton/FunctionButton'
import {data} from 'jquery'
import {relative} from 'path'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import {removeGorod} from '../../../functions/functions'


function ProductPage() {
    const [productData, setProductData] = useState<GetProductInfoResponse>()
    const [history, setHistory] = useState({})
    let {id} = useParams()
    const [count, setCount] = useState(1)
    const {store} = useContext(Context)
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const popupRef = useRef<HTMLDivElement>(null)

    const [Connected1S, setConnected1S] = useState(false)
    const [mainImage, setMainImage] = useState<string>('')
    // const [galleryImages, setGalleryImages] = useState<IGalleryImages[]>([])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [popupRef])

    const togglePopup = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        store.DataLoadingON()
        setConnected1S(false)
        window.scrollTo(0, 0)
        if (id) {
            HomeService.getProductInfo(id).then((data) => {
                setProductData(data.data)
                setMainImage(store.imgServer + data.data.image)
                // setGalleryImages(data.data.galleryImages)
                if (data.data.orderAmount != 0) {
                    setCount(data.data.orderAmount)
                }
            })
                .then(() => {
                    if (store.roles.find((role: any) => role.includes('Customer'))) {
                        CabinetBuyerService.historyViewed(true).then((data) => {
                            setHistory(data.data)
                        })
                    }
                }).catch(() => {
                store.ErrorON()
            }).finally(() => {
                store.DataLoadingOFF()
            })
        }
    }, [id])


    const Product: any = () => {
        const onDecreaseHandler = async () => {
            setCount(count - 1)
            if (productData && productData.orderId) {
                if (productData.orderId) {
                    CabinetBuyerService.basketUpdateAmount(productData.orderId, count - 1).then((result) => {
                        if (result.status === 200) {
                            setCount(result.data)
                            store.cartNumber()
                        }
                    }).catch(() => {
                        store.ErrorON()
                    })
                }
            }
        }

        const onIncreaseHandler = async () => {
            setCount(count + 1)
            if (productData && productData.orderId) {
                if (productData.orderId) {
                    CabinetBuyerService.basketUpdateAmount(productData.orderId, count + 1).then((result) => {
                        if (result.status === 200) {
                            setCount(result.data)
                            store.cartNumber()
                        }
                    }).catch(() => {
                        store.ErrorON()
                    })
                }
            }
        }

        const refHint1S = useRef(null)
        const refHint1SContainer = useRef(null)
        useOnClickOutside(refHint1S, () => setConnected1S(false), [refHint1SContainer])

        return (
            <>
                <div className='navigate'>
                    <CustomNavigateHeader
                        title={'Страница продукта'}
                        trashClickHandler={() => null}
                        withTrash={false}
                    />
                </div>
                <section
                    style={{minHeight: 'calc(100vh - 18px - 53px + 30px)'}}
                >
                    <div>
                        <h1 className='product-head'>
                            {productData?.name_of_product}
                        </h1>
                    </div>

                    <div className='product-image-container'>
                        <ImageWithSlides mainImage={mainImage} galleryImages={productData?.galleryImages}/>
                        {/* <CustomIMG src={productData?.image}/> */}
                        <div
                            className={'product-find-analog'}
                            onClick={() => navigate(SpaRoutes.RELATED_PRODUCTS + '/' + productData?.subCatId)}
                        >
                            Показать похожие
                        </div>
                        {store.roles.includes('Customer') && (<div className={'heart'}>
                            <Favorite
                                favorite={productData?.onFavorite}
                                id={productData?.productId ? productData?.productId : ''}
                            />
                        </div>)}
                    </div>
                    {productData && productData.galleryImages.length > 1 &&
                        <div className='product-thumbnails'>
                            <GalleryThumbnails galleryImages={productData?.galleryImages} mainImage={mainImage}
                                               setMainImage={setMainImage}/>
                        </div>
                    }

                    <div className='product-description'>
                        {(store.roles.includes('Customer') || store.roles.includes('Dealer')) && (
                            <div className='product-price'>
                                {/*{productData?.discount&&<p className="old-price">{productData?.price1} ₽</p>}*/}

                                <div className='new-price-amount-container'>
                                    <div
                                        style={{
                                            display: 'flex', position: 'relative'
                                        }}
                                    >
                                        <p className='new-price'>
                                            {productData?.visibleMaskPrice ? 'Уточняйте цену' : `${productData?.discount ? productData?.price2 : productData?.price1} ₽`}
                                        </p>
                                        <div
                                            className='productPage-1SConnected'
                                            onClick={() => {
                                                setConnected1S(!Connected1S)
                                            }}
                                            ref={refHint1SContainer}
                                        >
                                            <img
                                                id={'productPage-1SConnected-img'}
                                                src={`${productData?.isConnected1S ? '/Pictures/CheckGreen.svg' : '/Pictures/ExclamRed.svg'}`}
                                            />
                                            <img
                                                id={'productPage-cursor-img'}
                                                src={'/Pictures/cursor_click.svg'}
                                            />

                                            {Connected1S && (
                                                <div className='productPage-1SConnected-text' ref={refHint1S}>
                                                    {productData?.isConnected1S ? (<p>
                                                        Информация обновляется автоматически. В режиме реального
                                                        времени
                                                    </p>) : (<p>
                                                        Информация не обновляется автоматически. Уточняйте цену и
                                                        количество.
                                                    </p>)}
                                                </div>)}
                                        </div>
                                    </div>
                                    {productData?.amount != 0 && !productData?.visibleMaskAmount && !productData?.visibleMaskPrice && !productData?.orderId && store.roles.includes('Customer') && (
                                        <Counter
                                            maxValue={productData?.amount}
                                            count={count}
                                            onDecrease={onDecreaseHandler}
                                            onIncrease={onIncreaseHandler}
                                            disabled={false}
                                        />)}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <p className='quantity'>
                                        {productData?.visibleMaskAmount ? 'Под заказ' : `Кол-во на складе: ${productData?.amount != 0 ? productData?.amount + ' шт.' : 'Нет в наличии'}`}
                                    </p>
                                    {productData?.orderId && (<div
                                        style={{
                                            textAlign: 'right', width: '130px', marginLeft: 'auto'
                                        }}
                                        className={'productPage-alreadyInCart'}
                                    >
                                        {productData?.amount != 0 ? `Вы уже положили в корзину ${productData?.orderAmount != 0 ? productData?.orderAmount : count != 0 ? count : productData?.orderAmount} шт.` : 'Вы уже добавили товар в лист ожидания'}
                                    </div>)}
                                </div>
                            </div>)}
                    </div>

                    <ul className='description-provider'>
                        <li className='charact'>
                            <span>Адрес магазина:</span>
                            <span>
								{productData?.street + ' ' + productData?.number}
                            </span>
                        </li>
                        <li className='charact'>
                            <span>Поставщик:</span>
                            <span>
                                {productData?.image_supply_company && (<CustomIMG
                                    className='image_supply_company'
                                    src={productData?.image_supply_company_logo}
                                />)}
                                {productData?.supply_company}
                            </span>
                        </li>

                        {/*<li className="charact">*/}
                        {/*    <span>Бренд:</span><span>Juvederm Volbella</span>*/}
                        {/*</li>*/}
                    </ul>
                    <div className={'product-detail-filterList'}>
                        {productData?.filterAttr.map((attr: any) => {
                            return (<div className={'product-detail-filterItem'}>
                                {attr}
                            </div>)
                        })}
                    </div>
                    <div className='product-detailed-description'>
                        <h2 className='detailed-description-head'>Описание</h2>
                        <p className='description-list'>
                            {productData?.description}
                        </p>
                    </div>
                    {store.roles.includes('Customer') && (<div>
                        <FunctionButton
                            text={'Начать чат с поставщиком'}
                            onClickHandler={() => navigate(SpaRoutes.CHAT_DIALOG_PAGE + `/:${productData?.currentSupplierId}/:1/:0`)}
                            style={{
                                marginTop: 10, fontSize: 13, lineHeight: '16px', borderRadius: 7
                            }}
                        />
                    </div>)}
                </section>
            </>)
    }

    function BuyButton(props: {
        product: GetProductInfoResponse
        count: number
    }) {
        const {product, count} = props
        const navigate = useNavigate()
        const [orderId, setOrderId] = useState()

        const UpdateAmount = async (id: string) => {
            CabinetBuyerService.basketUpdateAmount(id, count === 1 ? count + 1 : count).then((result) => {
                if (result.status === 200) {
                    setCount(result.data)
                    store.cartNumber()
                }
            }).catch(() => {
                store.ErrorON()
            })
        }

        const buttonBuyHandler = () => {
            if (store.roles.find((role) => role.includes('Customer'))) {
                let id = product.orderId ? product.orderId : orderId
                store.DataLoadingON()
                if (id) {
                    UpdateAmount(id).finally(() => {
                        store.DataLoadingOFF()
                    })
                } else {
                    CabinetBuyerService.basketInsert(product.productId).then((response) => {
                        if (response.status === 200) {
                            setOrderId(response.data.orderId)
                            if (count > 1) {
                                UpdateAmount(response.data.orderId)
                            }
                            if (count === 1) store.cartNumber()
                            if (productData) {
                                setProductData({
                                    ...productData, orderId: response.data.orderId
                                })
                            }
                        }
                    }).catch(() => {
                        store.ErrorON()
                    }).finally(() => {
                        store.DataLoadingOFF()
                    })
                }
            } else {
                navigate('/login')
            }
        }

        return (
            <>
                {!!product.orderId ? (<Link to={'/catalogIndex'}>
                    <button className='product-button'>В каталог</button>
                </Link>) : (<button
                    className='product-button-one'
                    onClick={store.isAuth ? togglePopup : () => {
                        navigate(SpaRoutes.LOGIN)
                    }}
                >
                    {'Сравнить цену'}
                </button>)}
                {!store.roles.includes('Dealer') && (product.visibleMaskAmount || product.visibleMaskPrice ?
                    <Link to={SpaRoutes.CHAT_DIALOG_PAGE + `/:${productData?.currentSupplierId}/:1/:0`}>
                        <button className='product-button'>
                            Перейти в чат
                        </button>
                    </Link> : !!product.orderId ? (<Link to={SpaRoutes.CART}>
                        <button className='product-button'>
                            Перейти в корзину
                        </button>
                    </Link>) : (<button
                        onClick={buttonBuyHandler}
                        className='product-button-one'
                    >
                        {product.amount != 0 ? 'В корзину' : 'В лист ожидания'}
                    </button>))}

                <Link to={'/'} className={'product-go-home'}>
                    <img src={'/Pictures/Домой.svg'}/>
                </Link>
            </>)
    }

    SwiperCore.use([Navigation])

    function HistorySwiper(props: any) {
        const {history} = props
        const historyArray = Object.values(history)

        return (
            <Swiper
                freeMode={true}
                spaceBetween={20}
                breakpoints={{
                    // when window width is <1100px (480px)
                    0: {
                        slidesPerView: 2.1 //подобрано для ширины окна 140px;
                    }, // when window width is 1100px<w<1600px (1366px)
                    1100: {
                        slidesPerView: 5.637 //Подобрано таким образом, чтобы div одного слайда был 207px
                    }, // when window width is 1600px<w<1920px (1920px)
                    1600: {
                        slidesPerView: 6
                    }
                }}
            >
                {historyArray.map((history: any) => {
                    return (<SwiperSlide key={history.productId}>
                        <ProductCard isAuth={store.isAuth} item={history}/>
                    </SwiperSlide>)
                })}
            </Swiper>)
    }

    return (
        <div className='product-card'>
            {!store.isError && !store.isDataLoading && productData && (<>
                <Product item={productData}/>
                <div className='popup-container'>
                    {isOpen && (<>
                        <div
                            className='popup-overlay'
                            onClick={togglePopup}
                        >

                        </div>
                        <div
                            className='popup product-popup'
                            ref={popupRef}
                        >
                            <div className={'popup-header'}>
                                <div></div>
                                <div>Сравнение</div>

                                <div style={{
                                    marginLeft: 'auto', textTransform: 'uppercase'
                                }}
                                     onClick={togglePopup}>x
                                </div>
                            </div>
                            {productData.suppliers.length != 0 ? (productData.suppliers.map((number: OtherSupplier) => {
                                return (<div className='Supplier-comparison'>
                                    <div className={'supplier-comparison-info'}>
                                        <div className={'supplier-comparison-amount-price'}>
                                            <div className={'supplier-comparison-amount'}>
                                                {number.visibleMaskAmount ? 'под заказ' : number.amount > 0 ? `${number.amount} шт.` : 'нет в наличии'}
                                            </div>
                                            <div className='supplier-comparison-price'>
                                                {number.visibleMaskPrice ? 'уточнить цену' : number.isCheap ? number.price2 + ' ₽' : number.price1 + ' ₽'}
                                            </div>
                                        </div>
                                        <div className={'supplier-comparison-supplier-address'}>
                                            <div className={'supplier-comparison-supplier'}>
                                                <img className={'productCard-1SConnected-img'}
                                                     src={`${number.isConnected1S ? '/Pictures/CheckGreen.svg' : '/Pictures/ExclamRed.svg'}`}
                                                />
                                                <img className={'productCard-supplierLogo'}
                                                     src={store.imgServer + number.supplierlogo}/>
                                                {number.suppliername}
                                            </div>
                                            <div>
                                                {` ${removeGorod(number.city)}, ${number.street} - ${number.number}`}
                                            </div>
                                        </div>
                                    </div>

                                    <Link
                                        className='popup-button'
                                        onClick={() => togglePopup()}
                                        to={SpaRoutes.PRODUCT + `/:${number.productID}`}
                                    >
                                        ОК
                                    </Link>
                                </div>)
                            })) : (<div style={{textAlign: 'center'}}>
                                Другие поставщики отсутствуют
                            </div>)}
                        </div>
                    </>)}
                </div>
                {store.roles.find((role) => role.includes('Customer')) && (<>
                    <div
                        style={{marginTop: '30px'}}
                        className='historyPage-container'
                    >
                        <h3 className='history-head'>
                            Раннее вы смотрели:
                        </h3>
                        <HistorySwiper history={history}/>
                    </div>
                </>)}

                <div className='product-buttons'>
                    {productData && (<BuyButton
                        product={productData}
                        count={count}
                    />)}
                </div>

            </>)}
        </div>)
}

export default observer(ProductPage)
